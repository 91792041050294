<template>
  <div class="emailVerify-box">
    <nav-bar :title="$t(isEmail ? isEdit ? '修改郵箱地址' : '郵箱地址' : '綁定郵箱')" :isFixed="true" :goBack="goBack"></nav-bar>
    <div class="box-main">
      <div v-if="isEmail">
        <van-form @submit="nextSubmit()">
          <div class="tigs">{{ $t('當前綁定郵箱') }}</div>
          <div class="phone">{{ getemail }}</div>
          <div v-if="isEdit" style="margin-top: 24px;">
            <van-field v-model="CaptchaNewCode1" type="number" :placeholder="$t('請輸入驗證碼')">
              <template #button>
                <div class="btn-getcode" v-if="countDown !== 0">
                  {{ countDown }}s
                </div>
                <div class="btn-getcode" v-else @click="getEmailCode('2')">
                  {{ $t("獲取驗證碼") }}
                </div>
              </template>
            </van-field>
            <div class="error" v-if="isCode">
              {{ $t("請輸入驗證碼") }}
            </div>
          </div>
          <div style="margin: 40px 0 0;">
            <van-button color="#90D12E" block type="primary" v-if="!isEdit" @click="editPhone">
              {{ $t('更改郵箱') }}
            </van-button>
            <van-button v-else color="#90D12E" block type="primary" native-type="submit">
              {{ $t('下一步') }}
            </van-button>
            <van-button v-if="!isEdit" style="color: #666666;margin-top: 12px;" color="#EEEEEE" block @click="goBack">
              {{ $t('返回') }}
            </van-button>
          </div>
        </van-form>
      </div>
      <div v-else>
        <van-form @submit="onEmailSubmit()">
          <div class="title" style="margin-bottom: 20px;">{{ $t('請輸入新郵箱') }}</div>
          <van-field v-model="params.email" type="text" :placeholder="$t('請輸入新郵箱')" />
          <div class="error" v-if="verifyobj.emailregx">
            {{ $t("請輸入") }}{{ $t("有效電子郵箱地址") }}
          </div>
          <van-field v-model="CaptchaNewCode" type="number" :placeholder="$t('請輸入驗證碼')">
            <template #button>
              <div class="btn-getcode" v-if="countDown !== 0">
                {{ countDown }}s
              </div>
              <div class="btn-getcode" v-else @click="getNewEmailCode('2')">
                {{ $t("獲取驗證碼") }}
              </div>
            </template>
          </van-field>
          <div class="error" v-if="isCode">
            {{ $t("請輸入驗證碼") }}
          </div>
          <div style="margin: 30px 0 0;">
            <van-button color="#90D12E" block type="primary" native-type="submit">
              {{ $t('提交') }}
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import JSEncrypt from "jsencrypt"
import { mapState } from "vuex"
import { getCaptchaNew, validatePhoneCaptcha, updateEmail } from "@/api/user"

export default {
  name: "emailVerify",
  data () {
    return {
      isEdit: false,
      CaptchaNewCode: null,
      CaptchaNewCode1: null,
      countDown: 0,
      verifyList: [],
      params: {
        areaCode: null,
        email: "",
        phoneCaptcha: null
      },
      verifyobj: {
        emailregx: "",
      },
      isEmail: false,
      isCode: false,
      timer: null
    }
  },
  async created () {
    await this.$store.dispatch("user/getUserInfo")
  },
  watch: {
    'userInfo.email': {
      handler (val) {
        this.isEmail = val ? true : false
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    getemail () {
      if (this.userInfo.email) {
        const str = `${this.userInfo.email}`
        const before = str.slice(0, 3)
        const after = str.slice(str.length - 8, str.length)
        const stars = '*'.repeat(4)
        return before + stars + after
      } else {
        return ''
      }

    },


  },
  methods: {
    goBack () {
      if (!this.isEmail && this.userInfo.email) {
        this.isEmail = true
      } else {
        this.$router.back()
      }
    },
    editPhone () {
      this.isEdit = true
    },
    // 有绑定手机号下一步
    async nextSubmit () {
      // this.params.phone = this.userInfo.phone
      // this.params.areaCode = this.userInfo.areaCode
      // 验证验证码
      const CaptchaNewCode = this.CaptchaNewCode1.trim()
      const regex = /\d{6}/
      if (!CaptchaNewCode || !regex.test(CaptchaNewCode) || CaptchaNewCode.length > 6) {
        this.isCode = true
        return
      }
      // 有绑定手机验证验证码
      let result = await validatePhoneCaptcha({ email: this.userInfo.email, captcha: this.CaptchaNewCode1, type: "updateEmail" })
      if (result.resultID !== 1200) return
      this.isCode = false
      clearInterval(this.timer) // 清除定时器
      this.countDown = 0
      this.isEmail = false
    },
    // 绑定过手机号获取验证码
    async getEmailCode (type) {
      const publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/TS09a8Uez9/XfkDdK+Exx3ltVxrUlCjNPqRheMB/63iHyjx5k+N/48Nh9NFdCQKLEV/WxHQoszEBEh/sFofQuAx1wB9bcOtD98DT8Wkd53XmojxVfhtsVjeI/IBMim56mrO/LcKQDwc/6BsbFvilde002wx3CQ91OCw8XMptTwIDAQAB`
      let obj = {
        email: this.userInfo.email,
        type: "updateEmail",
      }
      let jsEncrypt = new JSEncrypt()
      jsEncrypt.setPublicKey(publicKey)
      let dataToEncrypt = JSON.stringify(obj)
      const res = await getCaptchaNew({
        id: jsEncrypt.encrypt(dataToEncrypt)
      })
      if (res.resultID !== 1200) return
      if (type == "2") {
        this.countDown = 60
        this.getTime()
      }
    },
    // 未绑定过手机号获取验证码
    async getNewEmailCode (type) {
      const isSubmit = await this.handleExamine()
      if (!isSubmit) return

      const publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/TS09a8Uez9/XfkDdK+Exx3ltVxrUlCjNPqRheMB/63iHyjx5k+N/48Nh9NFdCQKLEV/WxHQoszEBEh/sFofQuAx1wB9bcOtD98DT8Wkd53XmojxVfhtsVjeI/IBMim56mrO/LcKQDwc/6BsbFvilde002wx3CQ91OCw8XMptTwIDAQAB`
      let obj = {
        email: this.params.email,
        type: "newEmail",
      }
      let jsEncrypt = new JSEncrypt()
      jsEncrypt.setPublicKey(publicKey)
      let dataToEncrypt = JSON.stringify(obj)
      const res = await getCaptchaNew({
        id: jsEncrypt.encrypt(dataToEncrypt)
      })
      if (res.resultID !== 1200) return
      if (type == "2") {
        this.countDown = 60
        this.getTime()
      }
    },
    // 无手机号绑定下一步
    async onEmailSubmit () {
      const isSubmit = await this.handleExamine()
      if (!isSubmit) return
      // 验证验证码
      const CaptchaNewCode = this.CaptchaNewCode.trim()
      const regex = /\d{6}/
      if (!CaptchaNewCode || !regex.test(CaptchaNewCode) || CaptchaNewCode.length > 6) {
        this.isCode = true
        return
      }
      let result = await updateEmail({ email: this.params.email, emailCaptcha: this.CaptchaNewCode })
      if (result.resultID !== 1200) return
      this.$router.back()
      this.$toast(this.$t('操作成功'))
    },
    getTime () {
      this.timer = setInterval(() => {
        this.countDown--
        if (this.countDown === 0) {
          clearInterval(this.timer) // 清除定时器
          this.countDown = 0
        }
      }, 1000)
    },
    // 验证
    handleExamine () {
      const regxEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      this.verifyList = []
      if (!regxEmail.test(this.params.email)) {
        this.verifyobj["emailregx"] = true
      } else {
        delete this.verifyobj["emailregx"]
      }

      const keys = Object.keys(this.verifyobj)

      keys.forEach(item => {
        this.validatoFormFormcheck(item)
      })
      for (const key in this.verifyobj) {
        this.verifyobj[key] = ""
      }
      this.verifyList.forEach(item => {
        this.verifyobj[item] = true
      })
      if (this.verifyList.length > 0) {
        return false
      } else {
        return true
      }
    },
    validatoFormFormcheck (label) {
      if (!this.params[label]) {
        this.verifyList.push(label)
      } else {
        const index = this.verifyList.findIndex(item => item === label)
        if (index != -1) {
          this.verifyList.splice(index, 1)
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.emailVerify-box {
  padding-top: 44px;
  background: #FFFFFF;
  height: 100vh;

  .box-main {
    padding: 40px 26px 0;

    .title {
      font-weight: 500;
      font-size: 17px;
      color: #1A1A1A;
      line-height: 24px;
    }

    .tigs {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 22px;
    }

    .phone {
      margin-top: 12px;
      font-weight: bold;
      font-size: 20px;
      color: #1A1A1A;
      line-height: 30px;

    }

    .btn-getcode {
      font-weight: 400;
      font-size: 16px;
      color: #3491FA;
      line-height: 24px;
    }

    .error {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #f82207;
      line-height: 18px;
      padding-left: 12px;
      margin-top: 1px;
    }

    .areaCode {
      margin: 20px 0;
      font-weight: 400;
      font-size: 15px;
      color: #1A1A1A;
      line-height: 24px;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

    }


    :deep(.van-field) {
      border-bottom: 1px solid #F8F8F8;
    }
  }

  .titleArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px 0;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;

    .close {
      width: 20px;
    }
  }

  .phone-list {
    padding: 20px 16px;

    .actvie {
      border: 2px solid #90d12e !important;
    }

    .phone-item {
      padding: 20px;
      position: relative;
      background: #f8f8f8;
      border-radius: 8px 8px 8px 8px;
      margin-bottom: 12px;
      border: 2px solid transparent;

      .phone-text {
        font-size: 16px;
        color: #1a1a1a;
        line-height: 16px;
        text-align: center;
      }

      .phone-check {
        position: absolute;
        width: 24px;
        bottom: -1px;
        right: -1px;
      }
    }
  }
}
</style>